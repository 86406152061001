import React, { useEffect, useState } from 'react';
import LOGO_SVG from '../assets/svgs/Astute Health Main Logo.svg';

export default (props) => {
  const [currentNav, setCurrentNav] = useState('/');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const windowLocationArray = window.location.href.toString().split('/');
    setCurrentNav('/' + windowLocationArray[3]);
  });

  const generateMenuList = (menus = [
    { name: "Home", path: '' },
    { name: "Live Demo", path: "live-demo" },
    { name: "Features", path: "features" },
    { name: "Contact Us", path: "contact-us" }
  ], isMobile = false) => {
    const menuList = [];
    for (let item of menus) {
      const path = `/${item.path}`;
      menuList.push(
        <a
          href={path}
          aria-current="page"
          className={`nav-link-2 w-nav-link ${isMobile === true ? 'w--nav-link-open' : ''} ${currentNav === path ? 'w--current' : ''}`}>
          {item.name}
        </a>)
    }
    return menuList;
  }

  const displayMobileHamburger = () => {
    return (
      <div className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0"
        style={!isMenuOpen ? { display: 'none' } : { height: '3719.47px', display: "block", }}
      >
        <nav role="navigation"
          className="nav-menu-2 w-nav-menu"
          style={{ transform: "translateY(0px) translateX(0px)", transition: "transform 400ms ease 0s" }}
          data-nav-menu-open="">
          {generateMenuList(undefined, true)}
          <div className="div-block-14"><a href="#"
            className="button__primary navprimarybutton w-button">Request a demo</a>
          </div>
        </nav>
      </div>
    )
  }

  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar-2 w-nav">
      <div className="div-block-13">
        <a href="/"
          aria-current="page"
          className="brand-2 w-nav-brand w--current"
          aria-label="home">
          <img src={LOGO_SVG}
            loading="lazy"
            width="250"
            alt="Astute Health" />
        </a>
        {!isMenuOpen && <nav role="navigation" className="nav-menu-2 w-nav-menu" style={{ transform: "translateY(0px) translateX(0px)" }}>
          {generateMenuList()}
          <div className="div-block-14">
            <a href="#" className="button__primary navprimarybutton w-button">Request a demo</a>
          </div>
        </nav>}
        <div
          className={`menu-button-2 w-nav-button ${isMenuOpen ? 'w--open' : ''}`}
          onClick={() => { setIsMenuOpen(!isMenuOpen) }}
          style={{ WebkitUserSelect: "text" }}
          aria-label="menu"
          role="button"
          tabIndex="0"
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded={isMenuOpen.toString()}>
          <div className="w-icon-nav-menu"></div>
        </div>
      </div>
      {/* {isMenuOpen && displayMobileHamburger()} */}
      {displayMobileHamburger()}
      {!isMenuOpen && <div
        className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0">
      </div>}
    </div>
  )
}
