import React, { useState } from 'react';
require('./live-demo.css');

export default function LiveDemo(props) {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleClick = () => {
        window.open("https://app.astute.health/marketing", "_blank");
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <div className="container">
                <h1 className="heading-17">Live Demo</h1>
                <div className="description">
                    Explore the amazing free demo of astute by clicking on the image below.
                </div>
                <div 
                    className={`iframe-container ${hovered ? 'hovered' : ''}`} 
                    onMouseEnter={handleMouseEnter} 
                    onMouseLeave={handleMouseLeave}
                >
                    <iframe src="https://app.astute.health/marketing" title="Astute Health Marketing"></iframe>
                    {hovered && (
                        <div className="overlay">
                            <button className="overlay-button" onClick={handleClick}>
                                Take me there
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
