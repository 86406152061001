import React, { useEffect, useState } from 'react';
import Automated_White from '../../assets/svgs/Automated White.svg';
import Automated_Color from '../../assets/svgs/Automated Color.svg';
import Billing_White from '../../assets/svgs/Billing White.svg';
import Billing_Color from '../../assets/svgs/Billing Color.svg';
import Intake_White from '../../assets/svgs/Intake White.svg';
import Intake_Color from '../../assets/svgs/Intake Color.svg';
import Verifiaction_White from '../../assets/svgs/Verifiaction White.svg';
import Verifiaction_Color from '../../assets/svgs/Verifiaction Color.svg';
import Reporting_White from '../../assets/svgs/Reporting White.svg';
import Reporting_Color from '../../assets/svgs/Reporting Color.svg';
import Much_More_White from '../../assets/svgs/Much More White.svg';
import Much_More_Color from '../../assets/svgs/Much More Color.svg';

const DATA = {
    TAB_LIST: [
        {
            textElement: "Automated Compliance",
            selectedImageURL: Automated_White,
            unselectedImageURL: Automated_Color
        },
        {
            textElement: "Integrated Intake Forms",
            selectedImageURL: Intake_White,
            unselectedImageURL: Intake_Color
        },
        {
            textElement: "Real-time Benefits Verification",
            selectedImageURL: Verifiaction_White,
            unselectedImageURL: Verifiaction_Color
        },
        {
            textElement: "Billing Checks",
            selectedImageURL: Billing_White,
            unselectedImageURL: Billing_Color
        },
        {
            textElement: "Payment Processing",
            selectedImageURL: Reporting_White,
            unselectedImageURL: Reporting_Color
        },
        {
            textElement: "And much more",
            selectedImageURL: Much_More_White,
            unselectedImageURL: Much_More_Color
        }
    ],
    TAB_CONTENT: [
        {
            heading: "Automated Compliance",
            listText: [
                "Never miss a deadline with compliance reminders automatically generated from each chart",
                "Get alerted to POC and authorization renewals with convenient tracking",
                "Note validation to ensure documentation is thoroughly complete"
            ]
        },
        {
            heading: "Integrated Intake Forms",
            listText: [
                "Save time and eliminate paperwork with our mobile-friendly intake forms",
                "All your patients' information is uploaded directly to their chart in real time, including ID cards and referrals",
                "Cut down on documentation time with selected subjective information carrying over directly to the initial evaluation"
            ]
        },
        {
            heading: "Real-time Benefits Verification",
            listText: [
                "Get real-time patient benefits in seconds with just a payer name and member ID",
                "No more calling insurance companies or portals",
                "Pull PT-specific benefits for copays, KX modifier thresholds, and visit limits"
            ]
        },
        {
            heading: "Billing Checks",
            listText: [
                "Prevent denials with checks that ensure all claims sent to payers are 100% complete",
                "Automatically calculate units, assign modifiers, and be alerted to disallowed codes with a single click",
                "Built-in rules for Medicare and commercial payers"
            ]
        },
        {
            heading: "Payment Processing",
            listText: [
                "Seamlessly collect patient payments at check-in",
                "Stay on top of collections with reminders for outstanding balances",
            ]
        },
        {
            heading: "Everything you need",
            listText: [
                "There’s so much more under the hood of Astute Health. We’re always rolling out new features and we listen to our customers to deliver exactly what you need."
            ]
        }
    ]
}

export default (props) => {
    const [tabList, setTabList] = useState([]);
    const [tabContent, setTabContent] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Tab 1");

    useEffect(() => {
        renderTabList();
        renderTabContent();
    }, [selectedTab]);

    const renderTabList = () => {
        const tabList = [];

        let count = 1;
        for (let item of DATA.TAB_LIST) {
            const tabName = `Tab ${count}`;
            tabList.push(
                <a data-w-tab={tabName}
                    className={`custom-tab-tag w-inline-block w-tab-link ${selectedTab === tabName ? 'w--current' : ''}`}
                    id={`w-tabs-0-data-w-tab-${count - 1}`}
                    // href="#w-tabs-0-data-w-pane-0" 
                    role="tab"
                    aria-controls={`w-tabs-0-data-w-pane-${count - 1}`}
                    aria-selected="true"
                    onClick={() => { setSelectedTab(tabName); }}
                >
                    <img loading="lazy"
                        src={item.selectedImageURL}
                        alt="" className="solutions-tabs_link-image-2 muestra-1-2" />
                    <img loading="lazy"
                        src={item.unselectedImageURL}
                        alt="" className="solutions-tabs_link-image-2 is-tab-in-view-icon muestra-2" />
                    <div className="solutions-tabs_link-text-2">{item.textElement}</div>
                </a>
            );
            count++;
        }
        // @ts-ignore
        setTabList(tabList);
    }

    const renderTabContent = () => {
        const tabContent = [];

        let count = 1;
        for (let item of DATA.TAB_CONTENT) {
            const tabName = `Tab ${count}`;
            const listText = [];
            for (let text of item.listText) {
                listText.push(<li className="list-item">
                    <div className="text-block-2">{text}</div>
                </li>)
            }

            tabContent.push(
                <div data-w-tab={tabName}
                    className={`tab-panel w-tab-pane ${selectedTab === tabName ? 'w--tab-active' : ''}`}
                    id={`w-tabs-0-data-w-pane-${count - 1}`}
                    role="tabpanel"
                    aria-labelledby={`w-tabs-0-data-w-tab-${count - 1}`}>
                    <div className="div-block-34">
                        <h3 className="heading-5">{item.heading}</h3>
                        <ul
                            role="list"
                            className="list-3"
                        >
                            {listText}
                        </ul>
                    </div>
                </div>
            );
            count++;
        }
        // @ts-ignore
        setTabContent(tabContent);
    }

    return (
        <div>
            <div style={{ marginTop: '35px' }} className="page-padding-3">
                <h1 className="heading-16">The Astute Product Suite makes every member of your team more productive</h1>
                <div className="div-block-33">
                    <img
                        src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/6324c96b36302415df46fd9a_Swipe%20Left%20Icon.svg"
                        loading="lazy" width="20" alt="" className="image-35" />
                    <div data-current="Tab 1" data-easing="ease" data-duration-in="0" data-duration-out="0" className="tabs w-tabs">
                        <div className="tabs-menu w-tab-menu" role="tablist">
                            {tabList}
                        </div>
                        <div className="tabs-content w-tab-content">
                            {tabContent}
                        </div>
                    </div>
                </div>
            </div>
            {/* commented out container 2 code SEE IN FILE in backup folder */}
            {/* <FeaturesBottom /> */}
        </div>
    )
}