import React, { useState, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home, LiveDemo, Features, Contact } from './screens';
import { Navigation, Footer } from './components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SmoothScroll from 'smooth-scroll';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  // px offset matching to css
  offset: 50
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/live-demo",
    element: <LiveDemo />
  },
  {
    path: "/features",
    element: <Features />
  },
  {
    path: "/contact-us",
    element: <Contact />
  },
]);

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    // setLandingPageData(JsonData)
  }, [])

  return (
    // takes care of space in between
    <div style={{
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Navigation />
      <RouterProvider router={router} />
      <Footer />
      <ToastContainer
        position="top-center"
      // autoClose={5000}
      // hideProgressBar={false}
      // newestOnTop={false}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      // theme="light"
      />
    </div>
  )
}

export default App
