import React from 'react';
import { transformStyle } from "../../styles";
import MAIN_TOP_RIGHT from '../../assets/home/stock_clinic_emr-min.jpg';

export default (props) => {
  return (
    <div className="header-section home__section wf-section" style={{ marginBottom: '40px' }}>
      <div className="w-layout-grid header-grid">
        <div className="header-content">
          <h1 data-w-id="5ef8563e-e0f2-9aeb-a529-a163f7a23b63"
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            style={transformStyle}
            className="heading">
            <span className="text-span">Get paid faster, without the headache.</span> <br />That’s Astute.
          </h1>
          <h4 data-w-id="8a66c6ca-4941-3302-4d50-12e68eb46b97"
            style={transformStyle}
            className="heading-2">Astute Health helps practices translate clinical documentation into ready-to-bill claims.</h4>
          {/* TODO: remove this  */}
          {/* <a data-w-id="12b57e91-eec0-81f6-7c54-927d894d1ea0" style={transformStyle} href="/contact-us" className="button__primary button__header w-button">Request a demo</a> */}
          <div className="button-container">
            <a href="/contact-us" className="button__primary button__header features__heading w-button">Request a demo</a>
            <a href="/live-demo" className="button__primary button__header features__heading w-button">Live demo</a>
          </div>
        </div>
        <div className="div-block-17">
          <img className="image-15"
            src={MAIN_TOP_RIGHT}
            width="510" alt="App"
            style={{ ...transformStyle, borderRadius: '3%' }}
            sizes="(max-width: 479px) 80vw, (max-width: 991px) 60vw, (max-width: 1919px) 35vw, 550px"
            data-w-id="7bffd27d-399a-c500-f4b7-0b78d7e87f39" loading="lazy"
          // srcSet="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630d0e4dfd61a7e9358e3ac8_App%201-p-500.png 500w, https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/630d0e4dfd61a7e9358e3ac8_App%201.png 737w" 
          />
          {/* <img
            src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/6303d4d7faabbc92ae9f55f9_heroasset2.svg"
            loading="lazy" width="65"
            style={transformStyle}
            data-w-id="872aaf4f-b97f-7bc6-f140-2d0a8e19b361" alt="" className="image-18" />
          <img
            src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/6303d4d79454a43aedd2f56a_heroasset3.svg"
            loading="lazy" width="198"
            style={transformStyle}
            data-w-id="fff7a24c-a7f9-1d76-4cc7-28d56be5c4aa" alt="" className="image-17" />
          <img
            src="https://uploads-ssl.webflow.com/62fbb38d80f6ec00fbc7bf21/6303d4d7718403d8fa08e262_heroasset1.svg"
            loading="lazy" width="130"
            style={transformStyle}
            data-w-id="c2eb520f-c64c-dd5a-3dda-792dcf36843a" alt="" className="image-16" /> */}
        </div>
      </div>
      <div className="backgrounds_max-width">
        <div className="purple-background"></div>
        <div className="green-background"></div>
      </div>
    </div>
  )
}
